// import {BrowserRouter as Link} from 'react-router-dom';

function Navbar() {
  return (
    <div id="nav" className="bg-dark text-white">
      <div id="mobile-nav" onClick={() => ToggleDisplay()}><div className="fa fa-bars nav-link"></div></div>
      <div className="container">
        <nav id="navbar" className="navbar">
          <ul className="nav-links">
            {/* <PortfolioLink name="Landing Page" dest="/"/> */}
            <PortfolioLink name="About" dest="#about"/>
            <PortfolioLink name="Resume" dest="https://drive.google.com/file/d/1M6TXux3NlsZTBnbdVO9IVotE20dN0wMI/view" icon={'/images/leave_page.png'} newTab={true}/>
            <PortfolioLink name="Experience" dest="#experience"/>
            <PortfolioLink name="Skills" dest="#skills"/>
            <PortfolioLink name="Projects" dest="#projects"/>
            <PortfolioLink name="Contact" dest="#contact"/>
          </ul>
        </nav>
      </div>
    </div>
  );
}

function PortfolioLink(props) {
  //if we have a new tab (external website or diff page), we need to add target + rel
  if(props.newTab) {
    return (
      <a rel="noreferrer"  target="_blank" href={props.dest}><li className="nav-link">{props.name} <img src={props.icon} alt={props.icon} className="link-img"/></li></a>
    );
  }
  //to avoid warnings we are just returning without rel and target attribute if now new tab prop exists
  return(
    <a href={props.dest}><li className="nav-link">{props.name} <img src={props.icon} alt={props.icon} className="link-img"/></li></a>
  );
}

function ToggleDisplay() {
  let tog = document.getElementById("navbar");
  tog.classList.add("open-menu");
  tog.style.display = tog.style.display === "flex" ? "none" : "flex";
}

export default Navbar;
  