import React, { useEffect, useState } from 'react';
import ShowMore from '../../images/showMore.png';
import ShowLess from '../../images/showLess.png';

function Projects(props) {
  
  //fetch projects from json file
  const [projects, setProjects] = useState([]);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    fetch('data/projects.json', {headers: {'content-type': 'application/json'}}).then((res) => res.json()).then((data) => {
      setProjects(data.projects);
    });
  }, []);

  function handleShowMore() {
    setShowMore(!showMore);
  }

  //set show to all projects
  let show = projects;
  //than filter based on tags passed to Projects component
  if(projects && props.tags) {
    show = [];
    projects.forEach((project) => {
      project.tags.forEach((tag) => {;
        if(props.tags.indexOf(tag) >= 0) {
          if(show.indexOf(project) === -1) {
            show.push(project);
          }
        }
      })
    });
  }

  return (
  <div id="projects" className="my">
    <h3>Projects</h3>
    {props.tags === undefined ? <div className="alert">Try filtering projects by clicking on a skill listed above!</div> : null}
    <div className="row">
      {show ? show.map((project, i) => {
        if(!showMore && i > 1) {
          return null;
        }
        return(<Project key={i} title={project.title} url={project.url} description={project.description} link={project.github} tags={project.tags}/>)
      }): null}
      {show && show.length === 0 ? <div>No projects found with selected tag(s).</div> : null}
    </div>
    {show.length > 1 && showMore ? <ShowButton url={ShowLess} onClick={handleShowMore} show={showMore}/> : null}
    {show.length > 1 && !showMore ? <ShowButton url={ShowMore} onClick={handleShowMore} show={showMore}/> : null}
  </div>
  );
}

function Project(props) {
  return (
    <div className="col-50 project bg-dark text-white">
      <div className="project-header bg-light">
        <h3 className="project-header">{props.title}</h3>
      </div>
      <div className="row">
        {props.tags.map((tag, i) => {return <span key={i} className="project-tag">{tag}</span>})}
      </div>
      <div className="project-body">
        <div className="project-img-container"><img className="project-img" src={props.url} alt={props.title}/></div>
        <p>{props.description}</p>
        <a className="btn" target="_blank" rel="noreferrer" href={props.link}>Check it out!</a>
      </div>
    </div>
  )
}

function ShowButton(props) {
  return (
    <div className="text-center show-button" onClick={props.onClick}>
      <p>{props.show ? 'Show Less' : 'Show More'}</p>
      <img className="img" src={props.url} alt={props.url}/>
    </div>
  )
}

export default Projects;
