function Footer() {
  return (
  <div id="contact" className="bg-dark text-white">
    <div className="container">
      <div className="row-center">
        <div>Designed and Built by Dylan Lacelle ©</div>
      </div>
      <div className="row-center">
        <Icon url={'images/GitHub-Mark-Light-32px.png'} link={'https://github.com/DylanMLacelle'} alt={"GitHub"}/>
        <Icon url={'images/linkedin-3-32.png'} link={'https://www.linkedin.com/in/dlacelle/'} alt={"Linked In"}/>
      </div>
    </div>
  </div>
  );
}

function Icon(props) {
  return(<div className="footer-img-container">
    <a href={props.link} target="_blank" rel="noreferrer"><img src={props.url} alt={props.alt} className="img"/></a>
  </div>);
}

export default Footer;
