import About from './About';
import Experience from './Experience';
import Navbar from './Navbar';
import Skills from './Skills';
import Footer from './Footer';

function Portfolio() {
  return (
    <div>
        <Navbar/>
        <About/>
        <Experience/>
        <Skills/>
        <Footer/>
    </div>
  )
}

export default Portfolio;
  