import skillsText from '../../Data/skills.txt';
import React, { useState, useEffect } from 'react';
import Projects from './Projects';

function Skills() {
  const rows = 4;
  const [skills, setSkills] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    fetch(skillsText).then((skills) => skills.text()).then((skills) => {
      let temp = skills.split(', ');
      let len = (temp.length / rows);
      setSkills(splitArray(temp, len));
    });
  }, []);

  function handleSelect(e) {
    let temp = selected;
    if(temp.length) {
      if(temp.indexOf(e.target.textContent) >= 0) {
        temp.splice(temp.indexOf(e.target.textContent), 1);
      } else {
        temp.push(e.target.textContent);
      }
    } else {
      temp = [e.target.textContent];
    }
    setSelected([...temp]);
  }

  let skillRows = [];
  for(let i = 0; i < rows; i++) {
    if(skills[i] && skills[i].length) {
      skillRows.push(skills[i]);
    }  
  }
  
  return (
    <div id="skills">
      <div className="p-1 bg-dark text-white">
        <div className="container">
          <h3>Software Development Skills</h3>
          <div className="reverse-row">
            {skillRows ? skillRows.map((row, i) => {
              return (
                <div className="col">
                  <List key={i} onClick={handleSelect} list={row}/>
                </div>
              );
            }) : null}
          </div>
        </div>
      </div>
      <div className="container bg-primary">
        <div className="row">
          {selected && selected.length ? selected.map((tag, i) => {
            return <SelectedTags key={i} value={tag}/>
          }) : null}
          {selected && selected.length ? <ClearButton onClick={() => {setSelected([])}}/> : null}
        </div>
        {selected && selected.length ? <Projects tags={selected}/> : <Projects/>}
      </div>
  </div>
  );
}

function List(props) {
  return (
    <ul>
      {props.list.map((value, i) => {
        return (<li key={i} onClick={props.onClick} className="skill-list">{value}</li>);
      })}
    </ul>
  );
}

function SelectedTags(props) {
  return (
    <span className="tag">{props.value}</span>
  );
}

function splitArray(arr, size) {
  let peices = [];
  for (let i = 0; i < arr.length;) {
    peices.push(arr.slice(i, i += size));
  }
  return peices;
}

function ClearButton(props) {
  return (<span className="tag" onClick={props.onClick}>Clear Tags</span>);
}

export default Skills;
