function About() {
  return (
  <div id="about" className="bg-dark text-white">
    <div className="container">
      <div className="row">
        <div className="col-25">
          <img id="intro-img" className="img" src="images/profile_pic_2.png" alt="Dylan (me)"/>
        </div>
        <div className="col-75 ml">
          <div id="intro-text">
            <p className="text-emphasis">A disciplined self-starter.</p>
            <h1>I'm Dylan, a Software Developer.</h1>
            <p>
              I currently work as a <span className="text-emphasis">Software Developer</span> at <span className="text-emphasis">Brock Solutions</span>. 
              In May 2021, I graduated from <span className="text-emphasis">Conestoga College</span> with an advanced diploma in <span className="text-emphasis">Computer Programming</span>.
              Throughout the last three years at Conestoga and through personal endeavors, I have dove headfirst into many <span className="text-emphasis">programming languages, libraries, and frameworks</span>.  
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default About;
