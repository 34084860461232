//packages
import { BrowserRouter as Router, Route } from 'react-router-dom';

//components
import Portfolio from './Components/Portfolio/Portfolio';
//import Consulting from './Components/Consulting/Consulting';
//import Landing from './Components/Landing';

function App() {
  return (
    <Router>
      <div>
        <Route path="/" exact component={Portfolio}/>
        {/* <Route path="/portfolio" component={Portfolio}/>
        <Route path="/consulting" component={Consulting}/> */}
      </div>
    </Router>
  );
}

export default App;
