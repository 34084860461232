import React, { useEffect, useState } from 'react';

function Experience() {
//fetch experience from json file
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    fetch('data/experience.json', {headers: {'content-type': 'application/json'}}).then((res) => res.json()).then((data) => {
      setJobs(data.jobs);
      //console.log(data.jobs);
    });
  }, []);

  return (
  <div id="experience">
    <div className="container">
      <h2>Experience</h2>
      {jobs ? jobs.map((job, i) => {
        return <Job key={i} title={job.job_title} company={job.company_name} start={job.start_date} end={job.end_date} duties={job.duties} img={job.img_url}/>
      }): null}
    </div>
  </div>
  );
}

function Job(props) {
  return (
    <div className="row">
      <div className="col">
        <img className="img" src={props.img} alt={props.company}></img>
      </div>
      <div className="col-50 ml">
        <h3>{props.company}</h3>
        <div>{props.title}</div>
        <div>{props.start} - {props.end}</div>
      </div>
      <div className="col-75 mt-3">
        <ul>
          {props.duties ? props.duties.map((duty, i) => {
            return <li key={i}>{duty.duty}</li>
          }): null}
        </ul>
      </div>
    </div>
  );
}
  
export default Experience;
  